import React, { useEffect, useState } from 'react';
import { Container, Row, Image, Col, Button, Form } from "react-bootstrap";
import volverMobile from "../../assets/images/icons/VolverMobile.png";
import ContainerStyles from "../styles/Ubicaciones.module.css";
import volver from "../../assets/images/icons/Volver.png";
import world from "../../assets/images/icons/world.png";
import GoogleMapReact from 'google-map-react';
import { navigate } from 'gatsby';
import Header from "./Header";
import Marker from './Marker';

export default function Ubicaciones({ data }) {
  const locations = data.allLocation.edges.map(l => l.node),
  [purchaseMethod, setPurchaseMethod] = useState('personal'),
  [isMobile, setIsMobile] = useState(null),
  [screen, setScreen] = useState('map'),
  [markers, setMarkers] = useState([]),
  [active, setActive] = useState(-1),
  [zones, setZones] = useState([]),
  [zone, setZone] = useState(''),

  handleChange = ({ target }) => {
    setActive(-1);
    setZone(target.value);
    setMarkers(target.value ? locations.filter(l => l.type === purchaseMethod && l.zone === zone) : locations.filter(l => l.type === purchaseMethod));
  },
  
  uniq = arr => {
    let seen = {};
    return arr.filter(item => { return seen.hasOwnProperty(item) ? false : (seen[item] = true); });
  };

  useEffect(() => {
    setIsMobile((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1));
    setMarkers(locations.filter(l => l.type === 'personal'));
    setZones(uniq(locations.map(l => l.zone)));
  }, []);

  useEffect(() => {
    setActive(-1);
    zone ?
    setMarkers(locations.filter(l => l.type === purchaseMethod && l.zone === zone)) :
    setMarkers(locations.filter(l => l.type === purchaseMethod));
  }, [purchaseMethod]);

  return (
    <div className={ContainerStyles.bgmorado}>
      <Header activeItem='ubicaciones' />
      <Container fluid className={screen === "map" ? ContainerStyles.bgcontainer : ContainerStyles.bgcontainerselect} >
        <Row>
          <Col xs={12} md={4} className="px-3 d-flex flex-column">
            <div className="align-content-start">
              <Button className="bg-transparent pl-0 border-0 justify-content-start" onClick={() => navigate(-1)}>
                <Image src={volver} width="25" height="25" className="mt-1 d-none d-md-flex" alt="" />
                <Image src={screen === "map" ? volverMobile : volver}  width="35" height="35" className="mt-1 d-flex d-md-none" alt="" />
              </Button>
              <h1 className={screen === "map" ? ContainerStyles.TitleUb : ContainerStyles.TitleUbselect}>Dónde conseguir tus vinos</h1>
              {(!isMobile || screen === 'map') && <p className={ContainerStyles.SubtitleUb}>Conocé los puntos de venta donde podés conseguir Blasfemia</p>}
            </div>

            <Form>
              <Form.Label className={ screen === "map" ? ContainerStyles.SubtitleUb : ContainerStyles.SubtitleUbselect}>Seleccioná tu barrio</Form.Label>
              <Form.Control as='select' size="md" className={screen === "map" ? ContainerStyles.selectvtmap : ContainerStyles.selectvt} onChange={handleChange}>
                <option className={ContainerStyles.selectvtmap + " text-dark"} value=''>Ver todos los puntos de venta</option>
                {zones.map(z => <option key={z} className="text-dark text-capitalize" value={z}>{z}</option>)}
              </Form.Control>
            </Form>

            {
              (!isMobile || screen === 'list') &&
              <>
                <Row className="text-center mt-4">
                  <Col onClick={() => setPurchaseMethod('personal')} className={purchaseMethod === 'personal' ? ContainerStyles.selected : ContainerStyles.noselected} >
                    Tiendas Físicas
                  </Col>
                  <Col onClick={() => setPurchaseMethod('online')} className={purchaseMethod === 'online' ? ContainerStyles.selected :  ContainerStyles.noselected} >
                    Compra Online
                  </Col>
                </Row>
                <Row className={ContainerStyles.box}>
                  <ul className={ContainerStyles.list}>
                    {
                      markers.map((m, i) =>
                        <li key={i} className={ContainerStyles.itemsmapUb} onClick={() => setActive(i)}>
                          <Image src={world} width="15" height="15" className="mt-1 mr-2" alt="" />{m.name}
                        </li>
                      )
                    }
                  </ul>
                </Row>
                <Row>
                  {screen === 'list' && <Col xs={12} className="p-0 mt-2"><Button variant="light" className={ContainerStyles.direccionUb} onClick={() => setScreen('map')}>Ver Mapa</Button></Col>}
                </Row>
                {!isMobile && <p className={ContainerStyles.footerub + " d-none d-md-flex" }>BEBER CON MODERACIÓN Y EN CASA.<br/>PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.<br/>NO COMPRARTIR ESTE CONTENIDO CON MENORES.</p>}
              </>
            }
          </Col>
          {
            screen === 'map' &&
            <Col xs={12} md={8} className="p-0 mt-2 mt-md-0">
              <div className={ContainerStyles.mapgoogle}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyDVUMylNuJ1Ahf8aJHH0UE2mao1lKPEBNI' }}
                  defaultCenter={{lat: -34.61, lng: -58.5}}
                  defaultZoom={11}
                >
                  {markers.map((m, i) => <Marker key={i} lat={m.lat} lng={m.lng} data={m} active={active} setActive={setActive} idx={i} />)}
                </GoogleMapReact>
              </div>
              <Button variant="light" className={ContainerStyles.direccionUb} onClick={() => setScreen('list')}>Ver Direcciones</Button>
            </Col>
          }
        </Row>
      </Container>
    </div>
  );
}