import React, { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import activeMarker from '../../assets/images/icons/marcador rosa.svg';
import defaultMarker from '../../assets/images/icons/marcadorazul.svg';
import "../styles/Marker.css"

export default function Marker({ active, setActive, data, idx }) {
  const [status, setStatus] = useState(false),
  [show, setShow] = useState(false),
  btn = useRef(null),

  handleClick = e => {
    e.persist();
    if (status && !show) setActive(idx);
    else if (!status && show) setActive(-1);
    if (e.screenX || e.screenY) {
      setActive(!status ? idx : -1);
      setStatus(prev => !prev);
    }
  };

  useEffect(() => setStatus(active === idx), [active, idx]);

  useEffect(() => {
    if (status !== show) btn.current.click();
  }, [status]);

  return (
    <OverlayTrigger trigger="click" placement="top" onToggle={value => setShow(value)}
      overlay={
        <Popover>
          <div className="text-center py-1">
          <p className="px-4 pt-2 pb-0 m-0 nbl">Nombre del lugar</p>
          <p className="p-0 pb-1 m-0 place">{data.name}</p>
          <p className="p-0 pb-1 m-0 bd"></p>
          {
            data.link &&
            <a href={data.link} target="_blank" rel="noreferrer" className="vs p-0 m-0 text-decoration-none">
              Ver sitio
            </a>
          }
          </div>
          </Popover>
      }
    >
      <Button variant="ligth" ref={btn} onClick={handleClick}>
        <img src={status ? activeMarker : defaultMarker} alt='' />
      </Button>
    </OverlayTrigger>
  );
}