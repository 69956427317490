import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Ubicaciones from "../components/Ubicaciones";

export default function dondeestamos() {
  return (
    <StaticQuery
      query={graphql`
        query getLocations {
          allLocation {
            edges {
              node {
                name
                address
                zone
                city
                lat
                lng
                link
                type
              }
            }
          }
        }
      `}
      render={data => <Ubicaciones data={data} />}
    />
  );
}